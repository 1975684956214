import { Controller } from "@hotwired/stimulus"
import mapboxgl, { Map, NavigationControl, Popup } from "mapbox-gl"
import { template } from "dot"
import flatZip from "../lib/flat_zip"
import nextLink from "../lib/next_link"
import setSearchParams from "../lib/set_search_params"

export default class GlobeController extends Controller {
  static SECONDS_PER_REVOLUTION = 180
  static MIN_ZOOM = 2.5
  static MAX_CLUSTERING_ZOOM = 14
  static POLLING_RATE = 10

  static values = {
    clusterSizes: { type: Array, default: [5, 10] },
    clusterColors: { type: Array, default: ["#51bbd6", "#f1f075", "#f28cb1"] },
    clusterDiameters: { type: Array, default: [15, 20, 25] },
    clusterTextColor: { type: String, default: "#000000" },
    geojsonUrl: String,
    geojsonUrlParamOverrides: { type: Object, default: {} },
    pointDefaultColor: { type: String, default: "#11b4da" },
    pointHighlightColor: { type: String, default: "#11b4da" },
    pointDiameter: { type: Number, default: 7 },
    popupVariableName: { type: String, default: "feature" },
    showControls: { type: Boolean, default: true },
    spaceColor: { type: String, default: "#B6B7B6" },
    startingCoordinates: { type: Array, default: [0, 50] },
    style: {
      type: String,
      default: "mapbox://styles/mapbox/navigation-night-v1",
    },
    testMode: { type: Boolean, default: false },
    zoom: { type: Number, default: GlobeController.MIN_ZOOM },
  }

  static targets = ["map", "popupTemplate"]

  connect() {
    if (!mapboxgl.accessToken || !mapboxgl.supported()) {
      this.element.classList.add("hidden")
      return
    }

    this.map = new Map({
      center: this.startingCoordinatesValue,
      container: this.mapTarget,
      cooperativeGestures: true,
      minZoom: this.constructor.MIN_ZOOM,
      scrollZoom: false,
      style: this.testModeValue ? null : this.styleValue,
      testMode: this.testModeValue,
    })

    this.map.on("style.load", () => {
      this.map.setFog({
        "high-color": "rgba(0, 0, 0, 0)",
        "horizon-blend": 0,
        "space-color": this.spaceColorValue,
        "star-intensity": 0,
      })
    })

    if (this.showControlsValue) {
      this.map.addControl(
        new NavigationControl({ showCompass: false }),
        "bottom-right",
      )
    }

    this.resetZoom()
    this.resetLocation(globalThis.location.href, { animate: false })
    this.configureSpin()

    this.map.on("load", async () => {
      await this.configurePoints()

      this.configureClustering()

      if (this.hasPopupTemplateTarget) this.configurePopups()

      this.loadData(this.geojsonUrlValue)
    })
  }

  configureSpin() {
    this.map.on("mousedown", () => {
      this.stopSpin()
    })
    this.map.on("touchstart", () => {
      this.stopSpin()
    })
    this.map.on("zoom", () => {
      this.stopSpin()
    })
    this.map.on("moveend", () => {
      this.spinGlobe()
    })

    this.resumeSpin()
  }

  pauseSpin() {
    if (this.isSpinning()) {
      this.map.stop()
    }
    this.pausedSpinning = true
  }

  stopSpin() {
    if (this.isSpinning()) {
      this.map.stop()
    }
    this.stoppedSpinning = true
  }

  resumeSpin() {
    this.pausedSpinning = false
    this.spinGlobe()
  }

  isSpinning() {
    return !this.pausedSpinning && !this.stoppedSpinning
  }

  spinGlobe() {
    if (this.isSpinning()) {
      let distancePerSecond = 360 / this.constructor.SECONDS_PER_REVOLUTION
      const center = this.map.getCenter()
      center.lng -= distancePerSecond / this.constructor.POLLING_RATE
      this.map.easeTo({
        center,
        duration: 1000 / this.constructor.POLLING_RATE,
        easing: (n) => n,
      })
    }
  }

  configurePoints() {
    this.map.addSource("points", {
      type: "geojson",
      data: this.generateGeoJson(),
      cluster: true,
      clusterMaxZoom: this.constructor.MAX_CLUSTERING_ZOOM,
    })

    this.map.addLayer({
      id: "places",
      type: "circle",
      source: "points",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": [
          "case",
          ["boolean", ["get", "highlighted"], false],
          this.pointHighlightColorValue || this.pointDefaultColorValue,
          this.pointDefaultColorValue,
        ],
        "circle-radius": this.pointDiameterValue,
        "circle-blur": 0.1,
      },
    })

    this.map.on("click", "places", ({ point }) => {
      const feature = this.map.queryRenderedFeatures(point, {
        layers: ["places"],
      })[0]
      const url = feature.properties.url
      if (url) {
        globalThis.location = url
      }
    })

    this.map.on("mouseenter", "places", ({ point }) => {
      const feature = this.map.queryRenderedFeatures(point, {
        layers: ["places"],
      })[0]
      const url = feature.properties.url
      if (url) {
        this.map.getCanvas().style.cursor = "pointer"
      } else {
        this.map.getCanvas().style.cursor = ""
      }
    })

    this.map.on("mouseleave", "places", () => {
      this.map.getCanvas().style.cursor = ""
    })
  }

  configureClustering() {
    const circleColorsWithSteps = flatZip(
      this.clusterColorsValue,
      this.clusterSizesValue,
    )
    const circleRadiusesWithSteps = flatZip(
      this.clusterDiametersValue,
      this.clusterSizesValue,
    )
    const fontSizesWithSteps = flatZip(
      this.clusterDiametersValue.map(
        (diameter) => Math.ceil(diameter / 5) + 10,
      ),
      this.clusterSizesValue,
    )

    this.map.addLayer({
      id: "clusters",
      type: "circle",
      source: "points",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          ...circleColorsWithSteps,
        ],
        "circle-radius": [
          "step",
          ["get", "point_count"],
          ...circleRadiusesWithSteps,
        ],
      },
    })

    this.map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "points",
      filter: ["has", "point_count"],
      layout: {
        "text-field": ["get", "point_count_abbreviated"],
        "text-size": ["step", ["get", "point_count"], ...fontSizesWithSteps],
      },
      paint: {
        "text-color": this.clusterTextColorValue,
      },
    })

    this.map.on("click", "clusters", ({ point }) => {
      const feature = this.map.queryRenderedFeatures(point, {
        layers: ["clusters"],
      })[0]
      const clusterId = feature.properties.cluster_id
      this.map
        .getSource("points")
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return

          this.map.easeTo({
            center: feature.geometry.coordinates,
            zoom: zoom,
          })
        })
    })

    this.map.on("mouseenter", "clusters", () => {
      this.map.getCanvas().style.cursor = "pointer"
      this.pauseSpin()
    })

    this.map.on("mouseleave", "clusters", () => {
      this.map.getCanvas().style.cursor = ""
      this.resumeSpin()
    })
  }

  configurePopups() {
    const popup = new Popup({
      anchor: "top",
      closeButton: false,
      closeOnClick: false,
    })

    popup.on("open", () => {
      this.pauseSpin()
    })

    popup.on("close", () => {
      this.resumeSpin()
    })

    const popupTemplate = template(this.popupTemplateTarget.innerHTML, {
      selfcontained: true,
      argName: this.popupVariableNameValue,
    })

    let popupTimeoutID

    this.map.on("mouseenter", "places", ({ features, lngLat }) => {
      clearTimeout(popupTimeoutID)

      const coordinates = [...features[0].geometry.coordinates]
      while (Math.abs(lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += lngLat.lng > coordinates[0] ? 360 : -360
      }

      const popupHTML = popupTemplate(features[0].properties)
      popup.setLngLat(coordinates).setHTML(popupHTML).addTo(this.map)
    })

    this.map.on("mouseleave", "places", () => {
      popupTimeoutID = setTimeout(() => {
        const popupEl = popup.getElement()
        if (popupEl && popupEl.matches(":hover")) {
          popupEl.addEventListener(
            "mouseleave",
            () => {
              popup.remove()
            },
            { once: true },
          )
        } else {
          popup.remove()
        }
      }, 250)
    })

    this.map.on("touchend", "places", ({ features }) => {
      const mapBounds = this.map.getBounds()
      const center = [...features[0].geometry.coordinates]
      center[1] =
        center[1] - (mapBounds.getNorth() - mapBounds.getSouth()) * 0.2
      this.map.easeTo({
        center,
      })
    })
  }

  reloadData(url) {
    this.loadData(url)
    this.resetLocation(url, { animate: true })
  }

  async loadData(originalUrl) {
    if (!this.map) return

    this.currentlyLoadingUrl = originalUrl

    const data = this.generateGeoJson()
    let url = setSearchParams(originalUrl, this.geojsonUrlParamOverridesValue)

    const mapSource = this.map.getSource("points")

    if (mapSource) {
      while (url) {
        const response = await fetch(url, {
          headers: { Accept: "application/geo+json" },
        })
        const newData = await response.json()

        if (this.currentlyLoadingUrl !== originalUrl) return

        data.features.push(...newData.features)
        mapSource.setData(data)
        url = nextLink(response.headers.get("Link"))
      }
    }
    if (this.currentlyLoadingUrl === originalUrl) {
      this.currentlyLoadingUrl = null
    }
  }

  resetLocation(url, { animate } = {}) {
    if (!this.map) return

    const params = new URL(url).searchParams
    const longitudes = params.getAll("q[search][coordinates][][longitude]")
    const latitudes = params.getAll("q[search][coordinates][][latitude]")
    if (longitudes.some(Boolean) && latitudes.some(Boolean)) {
      this.stopSpin()
      this.map.fitBounds(
        [
          [longitudes[0], latitudes[0]],
          [longitudes[1], latitudes[1]],
        ],
        {
          animate,
          curve: 1.1,
        },
      )
    } else {
      this.resetZoom()
    }
  }

  resetZoom() {
    if (!this.map) return

    this.map.zoomTo(Math.max(this.zoomValue, this.constructor.MIN_ZOOM), {
      animate: true,
      duration: 1500,
    })
  }

  generateGeoJson() {
    return { type: "FeatureCollection", features: [] }
  }
}
